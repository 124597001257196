import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { LanguageProvider } from '../Language'
import { useMediaQuery } from 'react-responsive'
import Layout from '../Layout/Layout'
import LayoutMobile from '../Layout/LayoutMobile'
import Main from '../../pages/Main/Main'
import MainMobile from '../../pages/Main/MainMobile'
import Portfolio from '../../pages/Portfolio/Portfolio'
import PortfolioMobile from '../../pages/Portfolio/PortfolioMobile'
import SocialResponsibility from '../../pages/SocialResponsibility/SocialResponsibility'
import SocialResponsibilityMobile from '../../pages/SocialResponsibility/SocialResponsibilityMobile'
import Guarantees from '../../pages/Guarantees/Guarantees'
import GuaranteesMobile from '../../pages/Guarantees/GuaranteesMobile'
import FAQ from '../../pages/FAQ/FAQ'
import FAQMobile from '../../pages/FAQ/FAQMobile'
import Cooperation from '../../pages/Cooperation/Cooperation'
import CooperationMobile from '../../pages/Cooperation/CooperationMobile'
import RealEstateDetails from '../../pages/RealEstateDetails/RealEstateDetails'
import RealEstateDetailsMobile from '../../pages/RealEstateDetails/RealEstateDetailsMobile'

function DesktopApp() {
  return (
    <BrowserRouter>
      <LanguageProvider>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Main />} />
            <Route path='portfolio/*' element={<Portfolio />} />
            <Route path='portfolio/:type/:id' element={<RealEstateDetails />} />
            <Route
              path='social-responsibility'
              element={<SocialResponsibility />}
            />
            <Route path='guarantees' element={<Guarantees />} />
            <Route path='FAQ' element={<FAQ />} />
            <Route path='cooperation' element={<Cooperation />} />
          </Route>
        </Routes>
      </LanguageProvider>
    </BrowserRouter>
  )
}

function MobileApp() {
  return (
    <BrowserRouter>
      <LanguageProvider> {/* Обернул в LanguageProvider */}
        <Routes>
          <Route path='/' element={<LayoutMobile />}>
            <Route index element={<MainMobile />} />
            <Route path='portfolio/' element={<PortfolioMobile />} />
            <Route path='portfolio/:type/:id' element={<RealEstateDetailsMobile />} />
            <Route path='social-responsibility' element={<SocialResponsibilityMobile />} />
            <Route path='guarantees' element={<GuaranteesMobile />} />
            <Route path='FAQ' element={<FAQMobile />} />
            <Route path='cooperation' element={<CooperationMobile />} />
          </Route>
        </Routes>
      </LanguageProvider>
    </BrowserRouter>
  )
}


function App(): JSX.Element {
  const isMobile = useMediaQuery({ query: '(max-width: 426px)' })
  console.log('isMobile:', isMobile);

  return isMobile ? <MobileApp /> : <DesktopApp />;
}

export default App
