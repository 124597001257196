import React from 'react'
import { useLanguage } from '../Language'
import './Footer.css'

function Footer() {
  const { t } = useLanguage()

  return (
    <footer>
      <div className='footer-content'>
        {/*<a href='/social-responsibility'>*/}
        {/*  {t('footer.social-responsibility')}*/}
        {/*</a>*/}
        {/*<a href='/guarantees'>*/}
        {/*  {t('footer.guarantees')}*/}
        {/*</a>*/}
        {/*<a href='/FAQ'>{t('footer.faq')}</a>*/}
        {/*<a href='/cooperation'>*/}
        {/*  {t('footer.cooperation')}*/}
        {/*</a>*/}
      </div>
      <img src='/img/logo.svg' alt='logo-on-footer-page'/>
      {/*<div className='footer-space-around-text'>*/}
      {/*  <p>{t('footer.all-rights-reserved')}</p>*/}
      {/*  <p>{t('footer.privacy-policy')}</p>*/}
      {/*</div>*/}
      {/*<span className='footer-developer-text'>*/}
      {/*  <p>{t('footer.website-development')}</p>*/}
      {/*  <a href="https://www.softocean.com/" target="_blank" rel="noopener noreferrer">*/}
      {/*    SOFTOCEAN*/}
      {/*  </a>*/}
      {/*</span>*/}
    </footer>
  )
}

export default Footer
