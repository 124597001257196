import React, { useState } from 'react'
import { useLanguage } from '../../components/Language'
import './Cooperation.css'

const Cooperation: React.FC = () => {
  const { t } = useLanguage()
  const [isPopupVisible, setPopupVisible] = useState(false)
  const [message, setMessage] = useState('')

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value
    if (inputValue.length <= 500) {
      setMessage(inputValue)
    }
  }

  const handleButtonClick = () => {
    setPopupVisible(true)
  }

  const handlePopupClose = () => {
    setPopupVisible(false)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    // Handle form submission
  }

  return (
    <div className='cooperation-section'>
      <p className='cooperation-head'>{t('cooperation.title')}</p>
      <div className='cooperation-container-text-btn'>
        <p className='cooperation-container-text'>{t('cooperation.description')}</p>
        <div
          className='button-container-cooperation'
          onClick={handleButtonClick}
        >
          <p className='button-container-cooperation-text'>
            {t('forms.contact-us')}
          </p>
          <img
            src='./img/Button_circle.png'
            alt='button_circle'
            className='button-image-cooperation'
          />
        </div>
      </div>
      {isPopupVisible && (
        <div className='popup-overlay' onClick={handlePopupClose}>
          <div className='popup-content' onClick={(e) => e.stopPropagation()}>
            <p className='popup-overlay-text'>
              {t('forms.contact-us')}
            </p>
            <img
              src='./img/close-button.png'
              alt='close-button-overlay'
              className='close-button'
              onClick={handlePopupClose}
            />
            <form>
              <div className='popup-mail-phone'>
                <div>
                  <p> {t('forms.your-email')}</p>
                  <label>
                    <input
                      type='mail'
                      name='email'
                      placeholder={t('forms.email-placeholder')}
                      className='input-mail'
                    />
                  </label>
                </div>
                <div>
                  <p>{t('forms.your-phone')}</p>
                  <label>
                    <input type='tel' name='phone' className='input-phone' />
                  </label>
                </div>
              </div>
              <p>{t('forms.your-message')}</p>
              <label className='label-container'>
                {t('forms.your-message')}
                <textarea
                  name='message'
                  value={message}
                  onChange={handleChange}
                  maxLength={500}
                  placeholder={t('forms.message-placeholder')}
                  className='input-message'
                />
                <span className='char-count'>{message.length}/500</span>
              </label>
              <button type='submit' className='popup-content-button'>
                {t('forms.send')}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default Cooperation
