import React from 'react'
import { useLanguage } from '../Language'
import './FooterMobile.css'

function FooterMobile() {
  const { t } = useLanguage();

  return (
    <footer className='footer-mobile'>
      <div className='footer-content-mobile'>
        {/*<a href='/social-responsibility'>*/}
        {/*  {t('footer.social-responsibility')}*/}
        {/*</a>*/}
        {/*<a href='/guarantees'>*/}
        {/*  {t('footer.guarantees')}*/}
        {/*</a>*/}
        {/*<a href='/FAQ'>{t('footer.faq')}</a>*/}
        {/*<a href='/cooperation'>*/}
        {/*  {t('footer.cooperation')}*/}
        {/*</a>*/}
        <img src='/img/logo.svg' alt='logo-on-footer-page' />
      </div>
    </footer>
  )
}

export default FooterMobile
