import React from 'react';
import { useLanguage } from '../Language';
import './ErrorFallback.css'; // Подключаем стили

export const ErrorFallback: React.FC = () => {
  const { t } = useLanguage();

  const handleRefreshClick = () => {
    window.location.href = '/';
  };

  return (
    <div className="error-fallback">
      <h1>{t('error.something-wrong')}</h1>
      <button onClick={handleRefreshClick}>
        {t('error.to-main-page')}
      </button>
    </div>
  );
};
