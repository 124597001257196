import React from 'react'
import App from './components/App/App'
import './index.css'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './components/Error/ErrorFallback'
import { LanguageProvider } from './components/Language'
import { PropertyProvider } from './API/Context'
import ReactDOM from 'react-dom/client'


const rootElement = document.getElementById('root')

if (rootElement!.hasChildNodes()) {
    // @ts-ignore
    for (const child of [...rootElement.children]) {
        child.remove()
    }
}

ReactDOM.createRoot(document.getElementById('root')!).render(<React.StrictMode>
    <LanguageProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <PropertyProvider>
                <App/>
            </PropertyProvider>
        </ErrorBoundary>
    </LanguageProvider>
</React.StrictMode>);

