import React from 'react';
import { useLanguage } from '../../components/Language';
import './SocialResponsibility.css';

const SocialResponsibility = () => {
  const { t } = useLanguage();

  const socialResponsibilityData = [1, 2, 3, 4].map(num => ({
    digit: String(num).padStart(2, '0'),
    text: t(`social-responsibility.items.${num}`)
  }));

  return (
    <div className='socialresponsibility-section'>
      <p className='socialresponsibility-head'>
        {t('social-responsibility.title')}
      </p>
      {socialResponsibilityData.map((responsibility, index) => (
        <div key={index} className='socialresponsibility-text-container'>
          <p className='socialresponsibility-digit'>{responsibility.digit}</p>
          <p className='socialresponsibility-text'>{responsibility.text}</p>
        </div>
      ))}
    </div>
  );
};

export default SocialResponsibility;
