import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import en from '../translations/en.json';
import ru from '../translations/ru.json';

interface Translations {
  [key: string]: any;
}

const translations: { [key: string]: Translations } = { en, ru };

interface LanguageContextType {
  language: string;
  toggleLanguage: () => void;
  t: (key: string) => string;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  const [language, setLanguage] = useState<string>(() => {
    try {
      const storedLanguage = localStorage.getItem('language');
      return storedLanguage || 'ru';
    } catch (error) {
      console.error('Ошибка чтения из localStorage:', error);
      return 'ru';
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem('language', language);
    } catch (error) {
      console.error('Ошибка записи в localStorage:', error);
    }
  }, [language]);

  const toggleLanguage = () => {
    setLanguage((prev) => (prev === 'ru' ? 'en' : 'ru'));
  };

  const t = (key: string): string => {
    const keys = key.split('.');
    let value = translations[language];

    for (const k of keys) {
      if (value === undefined) return key;
      value = value[k];
    }

    // @ts-ignore
    return value as string || key;
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};
